import {
  AmountType,
  SelectedAmount,
  SelectedAmountStatuses,
} from '../../types';
import _ from 'lodash';
import { CreateActionParams } from '../actions';
import { EventNames } from '../../core/fedops';
import { getSelectedPreset } from '../../utils';
import { checkoutTipsTipAddedToCheckout } from '@wix/bi-logger-ecom-platform-data/v2';
import { BiLogger } from '../../core/bi/biLoggerFactory';

export type SelectTipAmountArgs = {
  presetId: string;
  debounceApi?: {
    wait: number;
  };
  selectedAmount: SelectedAmount;
};

export type SelectTipAmount = ({
  presetId,
  selectedAmount,
  debounceApi,
}: SelectTipAmountArgs) => void;

let debounceSetPreset: ReturnType<typeof _.debounce> | undefined;

const logBi = (biLogger: BiLogger, selectedAmount: SelectedAmount) => {
  const amount = Number(selectedAmount.amount || 0);
  const rate = Math.floor(
    selectedAmount.type === AmountType.Fixed ? amount * 100000 : amount * 1000,
  ); // ecom requirements for the bi

  biLogger?.report(
    checkoutTipsTipAddedToCheckout({
      rate,
      tipType: selectedAmount.type,
      isCustomTip: selectedAmount.isCustom || false,
      is_default: selectedAmount.isDefault || false,
    }),
  );
};

export function createSelectTipAmountAction({
  getControllerState,
  context,
}: CreateActionParams): SelectTipAmount {
  return async ({ presetId, selectedAmount, debounceApi }) => {
    const { fedopsLogger, flowAPI, api, biLogger } = context;
    const { translations } = flowAPI;
    fedopsLogger?.interactionStarted(EventNames.SelectTipAmount);

    const [state, setState] = getControllerState();
    const { presets } = state.presetInfo!;
    const newPresets = presets?.map((preset) => ({
      ...preset,
      isSelected: preset.id === presetId,
    }));

    if (biLogger) {
      logBi(biLogger, selectedAmount);
    }

    setState({
      presetInfo: { presets: newPresets },
      selectedAmount: {
        ...selectedAmount,
        status: debounceApi?.wait
          ? state.selectedAmount?.status
          : SelectedAmountStatuses.IN_PROGRESS,
      },
      errorMessage: undefined!,
    });

    const setPreset = async () => {
      try {
        if (debounceApi?.wait) {
          setState({
            selectedAmount: {
              ...selectedAmount,
              status: SelectedAmountStatuses.IN_PROGRESS,
            },
          });
        }

        const selectedPreset = getSelectedPreset(newPresets);

        await api.updateTip({
          selectedAmount,
          selectedPreset,
          purchaseFlowId: state.checkoutInfo?.checkout?.purchaseFlowId || '',
        });

        state.checkoutSlotProps?.refreshCheckoutCallback();

        setTimeout(() => {
          setState({
            selectedAmount: {
              ...selectedAmount,
              status: SelectedAmountStatuses.SUCCESS,
            },
          });
        }, 1500);

        fedopsLogger?.interactionEnded(EventNames.SelectTipAmount);
      } catch (e) {
        console.error('selectTipAmount failed: ', e);
        setState({
          selectedAmount: {
            ...selectedAmount,
            status: SelectedAmountStatuses.FAILURE,
          },
          errorMessage: translations.t(
            'app.wix-tips.widget.apply-tip-error-message',
          ),
        });
        setTimeout(() => {
          setState({
            selectedAmount: {
              ...selectedAmount,
              status: SelectedAmountStatuses.IDLE,
            },
          });
        }, 2000);
      }
    };

    if (debounceApi?.wait) {
      if (!debounceSetPreset) {
        debounceSetPreset = _.debounce(setPreset, debounceApi.wait);
      }
      await debounceSetPreset();
    } else {
      await setPreset();
    }
  };
}
