import { ActionsContext } from '../core/actionsContext/contextFactory';
import { ActionFactoryParams } from '../core/ControlledComponent/types';
import { ApplicationState } from '../types/ApplicationState';
import {
  createInitializeWidgetAction,
  InitializeWidget,
} from './initializeWidget';
import {
  createSelectTipAmountAction,
  SelectTipAmount,
} from './selectTipAmount';
import {
  createSetCheckoutSlotPropsAction,
  SetCheckoutSlotProps,
} from './setCheckoutSlotProps';

export type CreateActionParams = ActionFactoryParams<
  ApplicationState,
  ActionsContext
>;

export type ControllerActions = {
  initializeWidget: InitializeWidget;
  selectTipAmount: SelectTipAmount;
  setCheckoutSlotProps: SetCheckoutSlotProps;
};

export const createActions = (
  actionFactoryParams: ActionFactoryParams<ApplicationState, ActionsContext>,
): ControllerActions => {
  const actions = {
    initializeWidget: createInitializeWidgetAction(actionFactoryParams),
    selectTipAmount: createSelectTipAmountAction(actionFactoryParams),
    setCheckoutSlotProps: createSetCheckoutSlotPropsAction(actionFactoryParams),
  };
  return actions;
};
