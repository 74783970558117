import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { BiLogger, createBiLogger } from '../bi/biLoggerFactory';
import { Api } from '../../api';
import { FedopsLogger } from '../fedops';

export type ActionsContext = {
  flowAPI: ControllerFlowAPI;
  api: Api;
  biLogger?: BiLogger;
  fedopsLogger?: FedopsLogger;
};
export const createActionsContext = ({
  flowAPI,
}: {
  flowAPI: ControllerFlowAPI;
}): ActionsContext => {
  const api = new Api({ flowAPI });
  const biLogger = createBiLogger({ flowAPI, api });
  const fedopsLogger = new FedopsLogger(flowAPI);

  return {
    flowAPI,
    api,
    biLogger,
    fedopsLogger,
  };
};
