import {
  TipSettings,
  Preset as SettingsTipPreset,
} from '@wix/ambassador-tips-settings-v1-tip-settings/types';
import { AmountType, Preset, PresetType, SelectedAmount } from '../../types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { generateUUID } from '..';
import { ActionsContext } from '../../core/actionsContext/contextFactory';
import {
  UpdateTipChoiceRequest,
  TipType,
} from '@wix/ambassador-tips-v1-tip/types';
import { Checkout } from '@wix/ambassador-ecom-v1-checkout/types';
import { ExperimentsConsts } from '../../consts/experiments';
import { PriceFormattingConverter } from '../price';
import { getSiteLocale } from '../site';

export const presetMapper = ({
  settings,
  context,
  checkout,
}: {
  settings: TipSettings;
  context: ActionsContext;
  checkout: Checkout;
}): Preset[] => {
  if (settings.tipType === TipType.UNKNOWN_TIP_TYPE) {
    return [];
  }

  const subtotalAmount = Number(checkout.priceSummary?.subtotal?.amount || '0');

  const { flowAPI } = context;
  const currency = checkout.currency!;
  let presets: Preset[] = [];

  const shouldShowPresets =
    settings.presets?.length &&
    ((subtotalAmount > 0 && settings.tipType === TipType.PERCENTAGE) ||
      settings.tipType === TipType.AMOUNT);

  if (shouldShowPresets) {
    const zeroPreset: Preset = createPreset({
      tipPreset: '0',
      tipType: settings.tipType,
      flowAPI,
      currency,
    });

    presets = [
      zeroPreset,
      ...(settings.presets?.map((tipPreset: SettingsTipPreset) =>
        createPreset({
          tipPreset: `${tipPreset.value!}`,
          tipType: settings.tipType,
          flowAPI,
          currency,
          isDefault: tipPreset.default || false,
        }),
      ) || []),
    ];
  }

  const shouldShowCustomPreset =
    settings.allowCustomAmount ||
    (subtotalAmount === 0 && settings.tipType === TipType.PERCENTAGE);

  if (shouldShowCustomPreset) {
    const customPreset = createPreset({
      tipPreset: '0',
      tipType: settings.tipType,
      presetType: PresetType.Custom,
      flowAPI,
      currency,
    });

    presets.push(customPreset);
  }

  return presets;
};

const createPreset = ({
  tipPreset,
  tipType,
  presetType = PresetType.Predefined,
  flowAPI,
  currency,
  isDefault = false,
}: {
  tipPreset: string;
  tipType?: TipType;
  isSelected?: boolean;
  presetType?: PresetType;
  flowAPI: ControllerFlowAPI;
  currency: string;
  isDefault?: boolean;
}): Preset => {
  let formattedValue = '';

  if (tipType === TipType.PERCENTAGE) {
    formattedValue = `${tipPreset}%`;
  } else {
    const {
      experiments,
      controllerConfig: { wixCodeApi },
    } = flowAPI;

    const isWixTipsMatchCurrencyWithEcomEnabled = experiments?.enabled(
      ExperimentsConsts.WixTipsMatchCurrencyWithEcom,
    );

    if (isWixTipsMatchCurrencyWithEcomEnabled) {
      const locale = getSiteLocale(wixCodeApi);
      const currencyFormatter = PriceFormattingConverter.createPriceFormatter(
        locale,
        currency,
      );

      formattedValue = currencyFormatter(Number(tipPreset));
    } else {
      const currencyFormatter = flowAPI.getCurrencyFormatter({
        minimumIntegerDigits: 1,
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
        style: 'currency',
        currencyDisplay: 'narrowSymbol',
      });

      formattedValue = currencyFormatter({ currency, value: tipPreset });
    }
  }

  const presetId = generateUUID();

  return {
    id: presetId,
    amount: `${tipPreset}`,
    amountType: tipTypeToAmountTypeMapper(tipType),
    type: presetType,
    formattedValue,
    isSelected: false,
    isDefault,
  };
};

const tipTypeToAmountTypeMapper = (tipType?: TipType): AmountType => {
  if (tipType === TipType.PERCENTAGE) {
    return AmountType.Precentage;
  }

  return AmountType.Fixed;
};

export const mapSelectedPresetToUpdateTipRequest = ({
  selectedPreset,
  selectedAmount,
  purchaseFlowId,
}: {
  selectedPreset: Preset;
  selectedAmount: SelectedAmount;
  purchaseFlowId: string;
}): UpdateTipChoiceRequest => {
  const isCustom = selectedPreset.type === PresetType.Custom;

  const updateTipChoiceRequest: UpdateTipChoiceRequest = {
    purchaseFlowId: purchaseFlowId!,
    tipChoice: {
      tipType: amountTypeToTipTypeMapper(selectedAmount.type, isCustom),
      value: selectedAmount.amount,
    },
  };

  return updateTipChoiceRequest;
};

const amountTypeToTipTypeMapper = (
  amountType: AmountType,
  isCustom?: boolean,
): TipType => {
  if (isCustom) {
    return TipType.CUSTOM;
  }

  if (amountType === AmountType.Precentage) {
    return TipType.PERCENTAGE;
  }

  return TipType.AMOUNT;
};

export const getSelectedPreset = (presets: Preset[] = []): Preset =>
  presets.find((preset) => preset.isSelected)!;

export const getPresetById = (presets: Preset[] = [], id: string): Preset =>
  presets.find((preset) => preset.id === id)!;
