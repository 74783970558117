import { getCheckout } from '@wix/ambassador-ecom-v1-checkout/http';
import { GetCheckoutResponse } from '@wix/ambassador-ecom-v1-checkout/types';
import { ApiContext } from '../../types';

export interface GetCheckoutInfoArgs {
  checkoutId: string;
}

export const getCheckoutInfo = async ({
  flowAPI,
  checkoutId,
}: ApiContext & GetCheckoutInfoArgs): Promise<GetCheckoutResponse> => {
  const response = await flowAPI.httpClient.request(
    getCheckout({
      id: checkoutId,
    }),
  );

  return response.data;
};
