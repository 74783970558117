import { getCurrencyByKey } from '@wix/locale-dataset-javascript';

export type PriceFormatter = (price: number) => string;

const DIRECTION = {
  RTL: -1,
  LTR: 1,
};

export const RTL_MARKER = '\u200F';

const getSymbol = (formattedPrice: string) => {
  const direction = isNaN(Number(formattedPrice[0]))
    ? DIRECTION.LTR
    : DIRECTION.RTL;
  let cursor = direction === DIRECTION.LTR ? 1 : formattedPrice.length - 2;
  let offset = 1;
  while (isNaN(Number(formattedPrice[cursor]))) {
    offset++;
    cursor += direction;
  }
  const symbol =
    direction === DIRECTION.LTR
      ? formattedPrice.slice(0, offset)
      : formattedPrice.slice(-offset);
  return symbol;
};

export const getSymbolForViewer = (currencyCode: string) => {
  const wixCurrency = getCurrencyByKey(currencyCode.toUpperCase());
  return wixCurrency?.symbol;
};

export const PriceFormattingConverter = {
  createPriceFormatter: (
    locale: string,
    currencyCode: string,
  ): PriceFormatter => {
    const wixCurrency = getCurrencyByKey(currencyCode.toUpperCase());
    const { fraction, symbol } = wixCurrency ?? {};
    const formatOptions: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: 'symbol',
      minimumFractionDigits: 0,
      maximumFractionDigits: fraction ?? 2,
    };
    // Arabic locale is not supported by Intl.NumberFormat
    const _locale = locale.slice(0, 2) === 'ar' ? 'en-US' : locale;
    return (price: number) => {
      const res = new Intl.NumberFormat(_locale, formatOptions)
        .format(price)
        .trim()
        .replace(RTL_MARKER, '');
      const intlSymbol = getSymbol(res);
      if (intlSymbol && symbol) {
        return res.replace(intlSymbol, symbol);
      }
      return res;
    };
  },
};
