
  import userController from '/home/builduser/work/444e6e2ccea49f05/packages/wix-tips-viewer/src/components/WixTipsViewer/controller.ts';
  import createControllerWrapper from '@wix/yoshi-flow-editor/runtime/esm/controllerWrapper.js';

  
const wrapController = null;


  
var createHttpClient = null;

  

  import { initI18n as initI18n } from '@wix/yoshi-flow-editor/runtime/esm/i18next/init';


  
const multilingualDisabled = false;

  

    var createExperiments = null;
    var createWidgetExperiments = null;
    


  var sentryConfig = {
    DSN: 'https://7025fe2ce4ed466a9751d9607bc63a44@sentry.wixpress.com/5802',
    id: 'undefined',
    projectName: 'wix-tips-viewer-ip',
    teamName: 'n',
    errorMonitor: true,
  };

  var experimentsConfig = {"scopes":["test"],"centralized":true};

  var translationsConfig = {"icuEnabled":true,"defaultTranslationsPath":"/home/builduser/work/444e6e2ccea49f05/packages/wix-tips-viewer/src/assets/locales/messages_en.json","availableLanguages":["ar","bg","ca","cs","da","de","el","en","es","fi","fr","he","hi","hr","hu","id","it","ja","ko","lt","lv","ms","nl","no","pl","pt","ro","ru","sk","sl","sv","th","tl","tr","uk","vi","zh"]};

  var biConfig = null;

  var defaultTranslations = {"app.wix-tips.widget.header":"Would you like to leave a tip?","app.wix-tips.widget.custom-amount-button.text":"Custom amount","app.wix-tips.widget.button.label":"Apply","app.wix-tips.widget.apply-tip-error-message":"Tip wasn’t applied due to a technical error. Please try again.","app.wix-tips.widget.custom-amount-input.aria-label":"Tip amount in {currency}"};

  var fedopsConfig = null;

  import { createVisitorBILogger as biLogger } from '/home/builduser/work/444e6e2ccea49f05/packages/wix-tips-viewer/target/generated/bi/createBILogger.ts';

  const controllerOptions = {
    sentryConfig,
    biConfig,
    fedopsConfig,
    experimentsConfig,
    biLogger,
    translationsConfig,
    persistentAcrossPages: false,
    appName: "Wix Tips Plugin",
    componentName: "WixTipsViewer",
    appDefinitionId: "869a50c8-dd38-4324-b201-e33dc0747a5f",
    componentId: "d8f7e1d3-a41d-4473-a4c1-22b5207fd35e",
    projectName: "wix-tips-viewer",
    defaultTranslations,
    multilingualDisabled,
    shouldUseEssentials: true,
    withErrorBoundary: false,
    localeDistPath: "assets/locales"
  };

  const _controller = createControllerWrapper(userController, controllerOptions, {
    initI18n,
    createHttpClient,
    createExperiments,
  });

  export const wrap = wrapController;
  export const descriptor = {
    ...controllerOptions,
    id: controllerOptions.componentId,
    widgetType: "WIDGET_OUT_OF_IFRAME",
    controllerFileName: "/home/builduser/work/444e6e2ccea49f05/packages/wix-tips-viewer/src/components/WixTipsViewer/controller.ts",
  };

  export const controller = _controller
  export default controller;
