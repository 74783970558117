import { TipChoice } from '@wix/ambassador-tips-v1-tip/types';
import { getTipChoice } from '@wix/ambassador-tips-v1-tip/http';
import { ApiContext } from '../../types';

export type getUserSelectionFromCacheArgs = {
  purchaseFlowId: string;
};

export const getUserSelectionFromCache = async ({
  purchaseFlowId,
  flowAPI,
}: getUserSelectionFromCacheArgs & ApiContext): Promise<
  TipChoice | undefined
> => {
  try {
    const { data } = await flowAPI.httpClient?.request(
      getTipChoice({ purchaseFlowId }),
    );
    return data.tipChoice;
  } catch (e) {
    console.error('cannot get tip choice from cache', e);
    return undefined;
  }
};
