import { UpdateTipChoiceResponse } from '@wix/ambassador-tips-v1-tip/types';
import { updateTipChoice } from '@wix/ambassador-tips-v1-tip/http';
import { ApiContext } from '../../types';
import { Preset, SelectedAmount } from '../../../types';
import { mapSelectedPresetToUpdateTipRequest } from '../../../utils';

export type UpdateTipArgs = {
  selectedAmount: SelectedAmount;
  selectedPreset: Preset;
  purchaseFlowId: string;
};

export const updateTip = async ({
  flowAPI,
  selectedAmount,
  selectedPreset,
  purchaseFlowId,
}: ApiContext & UpdateTipArgs): Promise<UpdateTipChoiceResponse> => {
  const updateTipChoiceRequest = mapSelectedPresetToUpdateTipRequest({
    selectedAmount,
    selectedPreset,
    purchaseFlowId,
  });
  return (
    await flowAPI.httpClient.request(updateTipChoice(updateTipChoiceRequest))
  ).data;
};
