import { IWixAPI } from '@wix/yoshi-flow-editor';

const DEFAULT_LOCALE = 'en-US';

const isMultilingualEnabled = (wixCodeApi: IWixAPI) =>
  wixCodeApi.window.multilingual.isEnabled;

export const getSiteLocale = (wixCodeApi: IWixAPI) => {
  let locale = wixCodeApi.site.regionalSettings || DEFAULT_LOCALE;

  if (isMultilingualEnabled(wixCodeApi)) {
    const currentLanguage = wixCodeApi.window.multilingual.currentLanguage;

    locale =
      wixCodeApi.window.multilingual.siteLanguages.find(
        (language) => language.languageCode === currentLanguage,
      )?.locale ?? locale;
  }

  return locale;
};
