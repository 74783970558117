import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export enum EventNames {
  PageLoaded = 'page-loaded',
  WidgetInitialized = 'widget-initialized',
  SelectTipAmount = 'select-tip-amount',
}

export class FedopsLogger {
  constructor(private flowApi: ControllerFlowAPI) {}

  interactionStarted(eventName: EventNames) {
    this.flowApi.fedops.interactionStarted(eventName.toString());
  }

  interactionEnded(eventName: EventNames) {
    this.flowApi.fedops.interactionEnded(eventName.toString());
  }
}
