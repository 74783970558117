import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { isSSR } from './implementations/isSSR';
import { WIX_TIPS_APP_DEF_ID } from '../consts';
import { ApiContext } from './types';
import { getEnvironmentModes } from './implementations/environmentModes';
import {
  getTipSettings,
  GetPresetInfoArgs,
} from './implementations/getTipSettings';
import {
  getCheckoutInfo,
  GetCheckoutInfoArgs,
} from './implementations/getCheckoutInfo';
import {
  initialSelectedPreset,
  initialSelectedPresetArgs,
} from './implementations/initialSelectedPreset';
import {
  getUserSelectionFromCache,
  getUserSelectionFromCacheArgs,
} from './implementations/getUserSelectionFromCache';
import {
  updateTip,
  UpdateTipArgs,
} from './implementations/updateTip/updateTip';

export class Api {
  private readonly flowAPI: ControllerFlowAPI;

  constructor({ flowAPI }: { flowAPI: ControllerFlowAPI }) {
    this.flowAPI = flowAPI;
  }

  private getApiContext(): ApiContext {
    return {
      flowAPI: this.flowAPI,
      authorization: this.getAuthorization(),
      api: this,
    };
  }

  private getAuthorization() {
    const { controllerConfig } = this.flowAPI;
    return (
      (controllerConfig.wixCodeApi.site.getAppToken
        ? controllerConfig.wixCodeApi.site.getAppToken(WIX_TIPS_APP_DEF_ID)
        : undefined) || controllerConfig.appParams.instance
    );
  }

  getTipSettings(args: GetPresetInfoArgs) {
    return getTipSettings({
      ...args,
      ...this.getApiContext(),
    });
  }

  getCheckoutInfo(args: GetCheckoutInfoArgs) {
    return getCheckoutInfo({
      ...args,
      ...this.getApiContext(),
    });
  }

  isSSR() {
    return isSSR(this.getApiContext());
  }

  getEnvironmentModes() {
    return getEnvironmentModes({
      ...this.getApiContext(),
    });
  }

  initialSelectedPreset(args: initialSelectedPresetArgs) {
    return initialSelectedPreset({ ...args, ...this.getApiContext() });
  }

  getUserSelectionFromCache(args: getUserSelectionFromCacheArgs) {
    return getUserSelectionFromCache({ ...args, ...this.getApiContext() });
  }

  updateTip(args: UpdateTipArgs) {
    return updateTip({
      ...args,
      ...this.getApiContext(),
    });
  }
}
