import { TipSettings } from '@wix/ambassador-tips-settings-v1-tip-settings/types';
import { ApiContext } from '../../types';
import { queryTipSettings } from '@wix/ambassador-tips-settings-v1-tip-settings/http';

export type GetPresetInfoArgs = {};

export const getTipSettings = async ({
  flowAPI,
}: ApiContext & GetPresetInfoArgs): Promise<TipSettings | undefined> => {
  const settings = await flowAPI.httpClient.request(
    queryTipSettings({
      query: {
        filter: {
          default: true,
        },
      },
    }),
  );

  return settings.data.tipSettings?.[0];
};
