import { GetCheckoutResponse } from '@wix/ambassador-ecom-v1-checkout/types';
import { EnvironmentModes } from '../../api/implementations/environmentModes';

export type ApplicationState = {
  status: ApplicationStatus;
  checkoutSlotProps?: CheckoutSlotProps;
  checkoutInfo?: GetCheckoutResponse;
  presetInfo?: PresetInfo;
  customAmountConfig?: CustomAmountConfig;
  selectedAmount?: SelectedAmount;
  environmentModes: EnvironmentModes;
  errorMessage?: string;
};

export enum SelectedAmountStatuses {
  IDLE = 'IDLE',
  IN_PROGRESS = 'IN_PROGRESS',
  SUCCESS = 'SUCCESS',
  FAILURE = 'FAILURE',
}

export type PresetInfo = {
  presets: Preset[];
};

export enum ApplicationStatus {
  IDLE = 'IDLE',
  INITIALIZING = 'INITIALIZING',
}

export type SelectedAmount = {
  status?: SelectedAmountStatuses;
  amount: string;
  type: AmountType;
  isCustom: boolean;
  isDefault?: boolean;
};

export enum AmountType {
  Precentage = 'percentage',
  Fixed = 'fixed',
}

export enum PresetType {
  Custom,
  Predefined,
}

export type CustomAmountConfig = {
  isAllowed: boolean;
};

export type Preset = {
  id: string;
  type: PresetType;
  amountType: AmountType;
  amount: string;
  formattedValue: string;
  isSelected: boolean;
  isDefault: boolean;
};

export type CheckoutSlotProps = {
  checkoutId: string;
  refreshCheckoutCallback: Function;
};
